

.companyStructure.corporateOverview {
    width: 100%;
    margin-top: 32px;
    display: inline-flex;
    column-gap: 20px;
    justify-content: space-between;

    @media only screen and (max-width: 479px){
        display: grid;
        gap: 20px;
    }

    .companyStructureComponent,.companyProfile {
        width:100%;
        border: solid 2px #fc9c05;
        box-shadow: 0px 0px 8px  #61cefc; 
        border-radius: 18px;
        padding: 18px;
        @media only screen and (max-width: 479px){
            width: 350px;
            padding: 2px;
            .companyStructureTree {
                li{
                width: 100px;
                }
            }
        }
    }
    .companyStructureComponent {
        .treeNode {
            padding: 5px;
            border-radius: 8px;
            display: inline-block;
            border: solid 2px #fc9c05;
            box-shadow: 0px 0px 4px  #61cefc;
        }
    }
}
