.contactCard {
    display: grid;
    border: solid 2px #fc9c05;
    box-shadow: 0px 0px 8px  #61cefc;
    border-radius: 8px;
    padding: 8px;
    span {
        font-size: 18px;
        overflow-x: hidden;
        word-wrap: break-word;
        display: block;

        &.contactNumber > a {
            vertical-align: middle;
            display: inline-flex;
            width: 24px;
            height: 24px;
            &:hover {
                outline: solid 1px black;
                border-radius: 24px;
            }
            img {
            width: 24px;
            height: 24px;
            }
        }
    }
    path {
        fill:  #04ccfc;
    }
    @media only screen and (max-width: 479px){
        padding: 6px;
        height: 256px;
        span{
            font-size: 16px;
            padding: 6px 0;
        }
    }
}
