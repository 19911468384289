.navBarComponent {
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    background-color:  #61cefc ;
    vertical-align: middle;
    a {
        font-size: 18px;
        text-decoration: none;
        margin: auto;
        color: black;
        font-weight: 600;
        
        &:hover {
            text-decoration: underline;
            text-shadow: 0 0 0.01px black;
        }
        &.companyLogo:hover {
           outline: solid 2px black;
           outline-offset: 2px;
           border-radius: 32px;
        }
        @media only screen and (max-width: 479px){
            overflow-wrap: break-word;
            text-align: center;
            margin: 0 4px;
            &:hover {
                text-decoration: none;
                text-shadow: none;
            }
            &.companyLogo:hover {
                outline: none;
            }
        }
    }
}

.companyLogo,img {
    width: 48px;
    height: 48px;
    margin: 0 !important;
}
