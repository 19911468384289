.locationCard {
    display: inline-flex;
    gap: 8px;
    p {
        margin: 0;
        font-size: 18px;
    }
}

.location{
    text-align: center;
}
