.row {
    display: inline-flex;
    width: 100%;
    gap: 18px;
    margin: 18px 0;
    @media only screen and (max-width: 479px){
        gap: 8px;
        margin: 8px 0;
    }
}
.col-2 {
    width: 50%;
    height: 100%;
}
