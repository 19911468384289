.companyOverview {
    margin-top: 32px;
    width:100%;
    border: solid 2px #fc9c05;
    box-shadow: 0px 0px 8px  #61cefc; 
    border-radius: 18px;
    padding: 18px;
    @media only screen and (max-width: 479px){
        width: unset;
    }
    &.leftPane {
        text-align: left;
    }
}

.companyOverviewBody {
    display: inline-flex;
    gap: 18px;
    @media only screen and (max-width: 479px){
        display: grid;
    }
}

.companyOverviewContainer {
    text-align: center;
}

.landingPageButtons{
    margin: 36px 0;
    display: flex;
    justify-content: center;
    .buttonRow {
        display: inline-flex;
        justify-content: space-evenly;
        gap: 36px;
        margin: 0 auto;
        button {
            padding: 18px;
            font-size: 18px;
            box-shadow: 0px 0px 8px  #61cefc; 
            border-radius: 18px;
            border: solid 2px #fc9c05;
            background-color: #dcecc9 ;
            &:hover {
                border: solid 2px #dcecc9 ;
            }
        }
    }
}
