.contactFormContainer{
    display: grid;
    justify-content: center;
}

.contactForm {
    display: grid;
    font-size: 18px;
    label {
        margin: 12px 0;
    }
    input,textarea {
        padding: 8px;
        font-size: 16px;
        border-radius: 8px;
        border: solid 2px #fc9c05;
        box-shadow: 0px 0px 8px  #61cefc; 
        &:hover {
            outline: solid 2px #dcecc9 ;
            outline-offset: -2px;
            border-color: #dcecc9;
        }
    }

    textarea {
        height: 128px;
    }
    .buttonDiv {
        display: flex;
        justify-content: center;
    }
    input.sendButton {
        margin-top: 18px;
        width: 50%;
        padding: 18px;
        font-size: 18px;
        box-shadow: 0px 0px 8px  #61cefc; 
        border-radius: 18px;
        border: solid 2px #fc9c05;
        background-color: #dcecc9 ;
        &:hover {
            border: solid 2px #dcecc9 !important;
            outline: none;
        }
    }
    .statusMessage {
        display: flex;
        justify-content: center;
        height: 56px;
        align-items: center;
        gap: 8px;
        svg{
            width: 24px; 
            height: 24px;
        }
        .errorMessage{
            font-size: 20px;
        }
    }
}
