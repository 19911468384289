.imageGallery {
    display: inline-flex;
    width: 100%;
    padding-bottom: 24px;
    margin: 0 auto;
    .leftImage {
        display: grid;
        text-align: center;
        padding-top: 12px;
        width: 50%;
        img {
            width: 156px;
            height: 156px;
            margin: 0 auto !important;
        }
    }

    .rightImage {
        display: inline-flex;
        width: 50%;
        img {
            width: 667px;
            height: 375px;        
        }
       
        .leftButton,.rightButton {
            width: 48px;
            height: 48px;
            position:relative;
            background: none;
            border: none;
            border-radius: 48px;
            @media only screen and (max-width: 667px){
                visibility: hidden;
            }
            &:hover{
                border: solid 2px black;
            }

            svg {
                transform: scale(3);
                path {
                    fill: #fc9c05;
                }
            }
        }

        .leftButton {
            margin: auto -24px auto auto ;
        }

        .rightButton {
            margin: auto auto auto -24px;
        }
    }

    @media only screen and (max-width: 667px){
        height: unset;
        width: unset;

        .leftImage {
            height: auto;
            padding: 8px 2px;
            img {
                height: 48px;
                width: 48px;
            }
        }

        .rightImage{ 
            height: auto;
            img {
            height: 210px;
            width: 180px;
            }
        }

        .leftButton,.rightButton {
            height: 32px;
            width: 32px;
        }
    }

/* 
    &.companyBackground {
        border: none;
        column-gap: 20px;
        justify-content: space-between;
    } */
}

.companyBackground{
    @media only screen and (max-width: 479px){
        .desktop {
            display: none;
        }
    }

}